import axios from "axios";
import { User } from "../interfaces/user";

export const get = (userId : string) => {
    return axios.get(`/users/get?userId=${userId}`);
}

export const getCurrent = () => {
    return axios.get("/users/getCurrent");
}

export const getAll = () => {
    return axios.get("/users/getAll");
}

export const getAllRoles = () => {
    return axios.get("/users/getAllRoles");
}

export const create = (user: User) => {
    return axios.post("/users/create/", user);
}

export const update = (user: User) => {
    return axios.post("/users/update/", user);
}

export const activate = (userId : string) => {
    return axios.get("/users/activate?userId=" + userId)
}

export const deactivate = (userId : string) => {
    return axios.get("/users/deactivate?userId=" + userId)
}