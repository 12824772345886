import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { drawerWidth } from "../header/Header";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      position: "fixed",
      zIndex: 1,
      bottom: "0",
      backgroundColor: "white",
      borderTop: "1px solid",
      borderTopColor: theme.palette.divider,
      padding: theme.spacing(3),
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      width: "100%"
    },
  })
);

const Footer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <footer className={classes.footer}>
      &copy; <span className="mobileHidden">Copyright</span> Promat AG, {t("createdBy")} <Link component="a" href="https://www.chili.ch" target="_blank">chili.ch</Link>
    </footer>
  );
};

export default Footer;
