import * as React from "react";
import { useSelector } from "react-redux";
import { User } from "../../interfaces/user";
import { AppState } from "../../interfaces/general/app-state";
import { isAdministrator } from "../../utils/authorization";

interface Props {
  children: React.ReactNode;
  fallbackText?: string;
  authedUserId?: string
}

const ProtectedContent: React.FC<Props> = (props: Props) => {
  const currentUser = useSelector<AppState, User>((s) => s.user);

  return isAdministrator(currentUser) || props.authedUserId && currentUser.id === props.authedUserId ? (
    <>{props.children}</>
  ) : props.fallbackText ? (
    <small style={{textAlign:"center"}}>{props.fallbackText}</small>
  ) : null;
};

export default ProtectedContent;
