import { Status } from "../enums/status";
import { defaultLanguage, Language } from "./language";

export interface User {
    [key: string]: any
    id: string;
    email: string;
    name: string;
    roleName: string;
    password: string;
    confirmPassword: string;
    status: Status;
    languageId: number;
    language: Language;
}

export const defaultUser: User = {
    id: "",
    email: "",
    name: "",
    roleName: "",
    password: "",
    confirmPassword: "",
    status: Status.Active,
    userInstitutions: [],
    languageId: 0,
    language: defaultLanguage
}