import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// icons
import BuildingBlockIcon from "@material-ui/icons/Extension";
import PeopleIcon from "@material-ui/icons/People";
import BuildIcon from "@material-ui/icons/Build";
import HomeIcon from "@material-ui/icons/Home";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import ApplicationIcon from "@material-ui/icons/AccountTree";
import ManageUsersIcon from "@material-ui/icons/VpnKey";

import { NavLink as RouterLink } from "react-router-dom";
import ProtectedContent from "../protected-content/ProtectedContent";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 249,
    },
    toolbar: theme.mixins.toolbar,
    toolbarHeader: {
      minHeight: "68px",
      background: theme.palette.primary.main
    },
    logo: {
      height: "50px",
      paddingLeft: "9px",
      marginTop: "8px",
    },
    activeLink: {
      color: theme.palette.primary.light,
      "& .MuiListItemIcon-root": {
        color: theme.palette.primary.light,
      },
    },
  })
);

const Navigation: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => props.toggleDrawer(true)}
      onKeyDown={() => props.toggleDrawer(false)}
    >
      <div className={clsx(classes.toolbar, classes.toolbarHeader)}>
        <RouterLink to="/">
          <img src="/public/img/logo.svg" className={classes.logo} />
        </RouterLink>
      </div>
      <Divider />
      <List>
        <ListItem
          button
          component={RouterLink}
          to="/"
          activeClassName={classes.activeLink}
          exact
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={t("navigation.home")} />
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/building-blocks"
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <BuildingBlockIcon />
          </ListItemIcon>
          <ListItemText primary={t("navigation.buildingBlocks")} />
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/constructions"
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary={t("navigation.constructions")} />
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/applications"
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <ApplicationIcon />
          </ListItemIcon>
          <ListItemText primary={t("navigation.applications")} secondary={"& " + t("navigation.constructionGroups")} />
        </ListItem>        
      </List>
      <ProtectedContent>
        <Divider />
        <List>
            <ListItem
              button
              component={RouterLink}
              to="/users"
              activeClassName={classes.activeLink}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary={t("navigation.userManagement")} />
            </ListItem>
        </List>
      </ProtectedContent>
    </div>
  );
};

export default Navigation;
