import { User } from "../interfaces/user";

export const parseJwt = () => {
    if (!localStorage.getItem("jwtToken")) { return {}; }
    const base64Url = localStorage.getItem("jwtToken").split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

export const isAdministrator = (currentUser : User) => {
    return currentUser.roleName && (currentUser.roleName.toLowerCase() === "administrator" || currentUser.roleName.toLowerCase() === "superadmin");
}