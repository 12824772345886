export interface Language {
  [key: string]: any;
  id: number;
  shortcut: string;
  name: string;
}

export const defaultLanguage: Language = {
  id: 0,
  shortcut: "",
  name: "",
};