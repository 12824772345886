import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      marginTop: theme.spacing(5),
    },
    icon: {
      fontSize: "175px",
    },
    title: {
        marginBottom: 0
    },
    subTitle: {
        marginTop: theme.spacing(1),
        color: theme.palette.grey[700]
    }
  })
);

const BrowserNotSupported: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <SentimentDissatisfiedIcon className={classes.icon} />
      <h1 className={classes.title}>{t("browserNotSupported.title")}</h1>
      <h3 className={classes.subTitle}>{t("browserNotSupported.subtitle")}</h3>
    </div>
  );
};

export default BrowserNotSupported;
