import { User, defaultUser } from "../interfaces/user";
import { ActionType, createAction } from "typesafe-actions";
import { Dispatch } from "redux";
import * as ClientUserActions from "../client/user";
import * as ClientAuthenticationActions from "../client/authentication";
import { LoginDto } from "../interfaces/dto/login-dto";

const loginRequest = createAction("user/LOGIN_REQUEST")();
const loginFailure = createAction("user/LOGIN_FAILURE")();
const loginSuccess = createAction("user/LOGIN_SUCCESS")<string>();

const logoutSuccess = createAction("user/LOGOUT_SUCCESS")();

const getCurrentUserRequest = createAction("user/GET_CURRENT_REQUEST")();
const getCurrentUserFailure = createAction("user/GET_CURRENT_FAILURE")();
const getCurrentUserSuccess = createAction("user/GET_CURRENT_SUCCESS")<User>();

const updateCurrentUserRequest = createAction("user/UPDATE_CURRENT_REQUEST")();
const updateCurrentUserFailure = createAction("user/UPDATE_CURRENT_FAILURE")();
const updateCurrentUserSuccess = createAction("user/UPDATE_CURRENT_SUCCESS")<
  User
>();

export const login = (login: LoginDto) => {
  return (dispatch: Dispatch) => {
    dispatch(loginRequest());
    ClientAuthenticationActions.login(login)
      .then((response: any) => {
        dispatch(loginSuccess(response.data));
        localStorage.setItem("jwtToken", response.data);
        dispatch<any>(getCurrentUser());
      })
      .catch(() => {
        dispatch(loginFailure());
      });
  };
};

export const logout = () => {
  return (dispatch: Dispatch) => {
    localStorage.setItem("jwtToken", "");
    dispatch(logoutSuccess());
  };
};

export const getCurrentUser = () => {
  return (dispatch: Dispatch) => {
    dispatch(getCurrentUserRequest());
    if (localStorage.getItem("jwtToken")) {
      ClientUserActions.getCurrent()
        .then((response: any) => {
          dispatch(getCurrentUserSuccess(response.data));
        })
        .catch(() => {
          dispatch(getCurrentUserFailure());
        });
    } else {
      dispatch(getCurrentUserFailure());
    }
  };
};

export const updateCurrentUser = (user: User) => {
  return (dispatch: Dispatch) => {
    dispatch(updateCurrentUserRequest());
    return ClientUserActions.update(user)
      .then((response: any) => {
        dispatch(updateCurrentUserSuccess(response.data));
        return response;
      })
      .catch((error: any) => {
        dispatch(updateCurrentUserFailure());
        return Promise.reject(error);
      });
  };
};

export type UserActions = ActionType<
  | typeof loginRequest
  | typeof loginFailure
  | typeof loginSuccess
  | typeof logoutSuccess
  | typeof getCurrentUserRequest
  | typeof getCurrentUserFailure
  | typeof getCurrentUserSuccess
  | typeof updateCurrentUserRequest
  | typeof updateCurrentUserFailure
  | typeof updateCurrentUserSuccess
>;

export const userReducer = (state: User = defaultUser, action: UserActions) => {
  switch (action.type) {
    case "user/GET_CURRENT_SUCCESS":
      return action.payload;
    case "user/UPDATE_CURRENT_SUCCESS":
      return action.payload;
    case "user/LOGOUT_SUCCESS":
      return { defaultUser };
    default:
      return state;
  }
};
