import * as React from "react";
import { drawerWidth } from "../components/header/Header";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Routes from "../routes";
import { bindActionCreators } from "redux";
import { useThunkDispatch } from "../utils/thunk-dispatch";
import { getCurrentUser } from "../features/user-feature";
import { isIE } from "react-device-detect";
import BrowserNotSupported from "../components/browser-not-supported/BrowserNotSupported";
import { useSelector } from "react-redux";
import { AppState } from "../interfaces/general/app-state";
import { User } from "../interfaces/user";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "block",
      flexGrow: 1,
      padding: theme.spacing(3),
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      paddingBottom: "100px",
    },
    toolbar: theme.mixins.toolbar,
  })
);

const App: React.FC = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const asyncDispatch = useThunkDispatch();
  const actions = bindActionCreators({ getCurrentUser }, asyncDispatch);
  const user = useSelector<AppState, User>((state) => state.user);

  React.useEffect(() => {
    actions.getCurrentUser();
  }, []);

  React.useEffect(() => {
    if (user && user.language && user.language.shortcut) {
      i18n.changeLanguage(
        user.language.shortcut
      );
    }
  }, [user]);

  return (
    <>
      <Header />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {isIE ? <BrowserNotSupported /> : <Routes />}
      </main>
      <Footer />
    </>
  );
};

export default App;
