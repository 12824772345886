import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      dark: "#1976d2",
      main: "#009fe3",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#323232",
      dark: '#000000',
      contrastText: "#fff",
    },
    error: {
      main: "#f44336"
    },
    background: {
      default: "#fff",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});
