import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { useThunkDispatch } from "../../utils/thunk-dispatch";
import { updateCurrentException } from "../../features/exception-feature";
import { useSelector } from "react-redux";
import { AppState } from "../../interfaces/general/app-state";
import { Exception, defaultException } from "../../interfaces/general/exception";
import { getExceptionMessage } from "../../utils/exception";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme/theme";

const ErrorAlert: React.FC = () => {
  const { t } = useTranslation();
  const [showErrorDetails, setShowErrorDetails] = React.useState(false);
  const asyncDispatch = useThunkDispatch();
  const currentException = useSelector<AppState, Exception>((s) => s.exception);

  const handleClose = () => {
    asyncDispatch(updateCurrentException(null));
  };

  const toggleErrorDetails = () => {
    setShowErrorDetails(!showErrorDetails);
  };

  const renderErrorDetails = () => {
    var messages = getExceptionMessage(currentException, []);
    return messages
      ? messages.map((m : string, index: number) => {return <React.Fragment key={index}><span>{m}</span><br/></React.Fragment >})
      : t("errorHasNoAdditionalInformations");
  };

  return (
    <div>
      <Dialog
        open={currentException != null && currentException != defaultException}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={theme.palette.error.main}>
          {"Etwas ist schief gelaufen."}
        </DialogTitle>
        <DialogContent>
          <Typography id="alert-dialog-description">
            {t("unexpectedErrorOccured")}
          </Typography>
          <Box m={1} />
          {showErrorDetails ? (
            <>
              <Button
                onClick={toggleErrorDetails}
                color="default"
                size="small"
                startIcon={<ExpandLessIcon />}
              >
                {t("showLess")}
              </Button>
              <Box m={1.5} />
              <Typography variant="body2">{currentException && renderErrorDetails()}</Typography>
            </>
          ) : (
            <Button
              onClick={toggleErrorDetails}
              color="default"
              size="small"
              startIcon={<ExpandMoreIcon />}
            >
              {t("showMore")}
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
          {t("close")}
          </Button>          
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ErrorAlert;
