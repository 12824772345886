import axios from "axios";
import { LoginDto } from "../interfaces/dto/login-dto";
import { PasswordResetDto } from "../interfaces/dto/password-reset-dto";

export const getPublicRecaptchaKey = () => {
  return axios.get("/authentication/getPublicRecaptchaKey");
};

export const login = (loginDto: LoginDto) => {
  return axios.post("/authentication/login", loginDto);
};

export const recover = (email: string) => {
  return axios.post(`/authentication/recoverPassword?email=${email}`);
};

export const reset = (passwordResetDto: PasswordResetDto) => {
  return axios.post(`/authentication/resetpassword`, passwordResetDto);
};