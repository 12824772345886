import axios from "axios";
import appSettings from "../app-settings";
import i18n from "../i18n";

export const configAxios = () => {
  i18n.loadNamespaces("response");
  axios.defaults.baseURL = appSettings.apiPath;
  axios.interceptors.request.use(function (config) {
    const token = `bearer ${localStorage.getItem("jwtToken")}`;
    config.headers.Authorization = token;

    return config;
  });

  axios.interceptors.response.use(
    function (response) {
      if (
        typeof response.data === "string" ||
        response.data instanceof String
      ) {
        let key = "response:" + response.data;
        if (i18n.exists(key)) {
          response.data = i18n.t("response:" + response.data);
        }
      }
      return response;
    },
    function (error) {
      if (
        (error.response.data && typeof error.response.data === "string") ||
        error.response.data instanceof String
      ) {
        error.response.data = i18n.t("response:" + error.response.data);
      }
      // catch non-customized 404, 403 etc.
      if (error.response.data === "" && error.response.status) {
        error.response.data = i18n.t("response:" + error.response.status);
      }
      return Promise.reject(error);
    }
  );
};
