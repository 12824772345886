import { User, defaultUser } from "../user";
import { Request } from "./Request";
import { Exception, defaultException } from "./exception";

export interface AppState {
  requests: Request[];
  user: User;
  exception: Exception;
}

export const initialState: AppState = {
  requests: [],
  user: defaultUser,
  exception: defaultException
};
