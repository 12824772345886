import * as React from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "./components/loading/Loading";
import * as loadable from 'react-loadable';
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import { useSelector } from "react-redux";
import { AppState } from "./interfaces/general/app-state";
import { User } from "./interfaces/user";
import { Request } from "./interfaces/general/request";
import { isAdministrator } from "./utils/authorization";

const AsyncHomeContainer = loadable ({loader: () => import(/* webpackChunkName: "Home" */ "./containers/home/Home"), loading: Loading});
const AsyncLoginContainer = loadable ({loader: () => import(/* webpackChunkName: "Login" */ "./containers/authentication/Login"), loading: Loading});
const AsyncForgotPasswordContainer = loadable ({loader: () => import(/* webpackChunkName: "ForgotPassword" */ "./containers/authentication/ForgotPassword"), loading: Loading});
const AsyncResetPasswordContainer = loadable ({loader: () => import(/* webpackChunkName: "ResetPassword" */ "./containers/authentication/ResetPassword"), loading: Loading});
const AsyncLogoutContainer = loadable ({loader: () => import(/* webpackChunkName: "Logout" */ "./containers/authentication/Logout"), loading: Loading});
const AsyncProfileContainer = loadable ({loader: () => import(/* webpackChunkName: "Profile" */ "./containers/profile/Profile"), loading: Loading});
const AsyncApplicationsContainer = loadable ({loader: () => import(/* webpackChunkName: "Applications" */ "./containers/application/Applications"), loading: Loading});
const AsyncCreateApplicationContainer = loadable ({loader: () => import(/* webpackChunkName: "CreateApplication" */ "./containers/application/Create"), loading: Loading});
const AsyncUpdateApplicationContainer = loadable ({loader: () => import(/* webpackChunkName: "UpdateApplication" */ "./containers/application/Update"), loading: Loading});
const AsyncConstructionGroupsContainer = loadable ({loader: () => import(/* webpackChunkName: "ConstructionGroups" */ "./containers/construction-group/ConstructionGroups"), loading: Loading});
const AsyncCreateConstructionGroupContainer = loadable ({loader: () => import(/* webpackChunkName: "CreateConstructionGroup" */ "./containers/construction-group/Create"), loading: Loading});
const AsyncUpdateConstructionGroupContainer = loadable ({loader: () => import(/* webpackChunkName: "UpdateConstructionGroup" */ "./containers/construction-group/Update"), loading: Loading});
const AsyncConstructionsContainer = loadable ({loader: () => import(/* webpackChunkName: "Constructions" */ "./containers/construction/Constructions"), loading: Loading});
const AsyncCreateConstructionContainer = loadable ({loader: () => import(/* webpackChunkName: "CreateConstruction" */ "./containers/construction/Create"), loading: Loading});
const AsyncUpdateConstructionContainer = loadable ({loader: () => import(/* webpackChunkName: "UpdateConstruction" */ "./containers/construction/Update"), loading: Loading});
const AsyncBuildingBlocksContainer = loadable ({loader: () => import(/* webpackChunkName: "BuildingBlocks" */ "./containers/building-block/BuildingBlocks"), loading: Loading});
const AsyncCreateBuildingBlockContainer = loadable ({loader: () => import(/* webpackChunkName: "CreateBuildingBlock" */ "./containers/building-block/Create"), loading: Loading});
const AsyncUpdateBuildingBlockContainer = loadable ({loader: () => import(/* webpackChunkName: "UpdateBuildingBlock" */ "./containers/building-block/Update"), loading: Loading});
const AsyncDetailBuildingBlockContainer = loadable ({loader: () => import(/* webpackChunkName: "DetailBuildingBlock" */ "./containers/building-block/Detail"), loading: Loading});
const AsyncUsersContainer = loadable ({loader: () => import(/* webpackChunkName: "Users" */ "./containers/user/Users"), loading: Loading});
const AsyncCreateUserContainer = loadable ({loader: () => import(/* webpackChunkName: "CreateUser" */ "./containers/user/Create"), loading: Loading});
const AsyncUpdateUserContainer = loadable ({loader: () => import(/* webpackChunkName: "UpdateUser" */ "./containers/user/Update"), loading: Loading});

const AsyncNotFoundContainer = loadable({loader: () => import(/* webpackChunkName: "NotFound" */ "./containers/error-pages/NotFound"), loading: Loading});

const Routes : React.FC = () => {
    const requests = useSelector<AppState, Request[]>(state => state.requests);
    const [userRequestStatus, setUserRequestStatus] = React.useState("REQUEST");
    const user = useSelector<AppState, User>((state) => state.user);
    
    React.useEffect(() => {
        var userRequestString = requests.filter(x => x.type === "user/GET_CURRENT").length > 0 ? requests.filter(x => x.type === "user/GET_CURRENT")[0].status : "REQUEST";
        setUserRequestStatus(userRequestString);
    }, [requests]);

    return (
        <Switch>
            <Route exact path="/" render={() => <AsyncHomeContainer /> } />
            <Route exact path="/login/:url?/:key?" render={() => <AsyncLoginContainer /> } />
            <Route exact path="/forgot-password" render={() => <AsyncForgotPasswordContainer /> } />
            <Route exact path="/reset-password" render={() => <AsyncResetPasswordContainer /> } />
            <Route exact path="/logout" render={() => <AsyncLogoutContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/profile" render={() => <AsyncProfileContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/applications" render={() => <AsyncApplicationsContainer /> } />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && isAdministrator(user)} exact path="/applications/create" render={() => <AsyncCreateApplicationContainer /> } />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && isAdministrator(user)} exact path="/applications/:applicationId/update" render={() => <AsyncUpdateApplicationContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/applications/:applicationId/groups" render={() => <AsyncConstructionGroupsContainer /> } />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && isAdministrator(user)} exact path="/applications/:applicationId/groups/create" render={() => <AsyncCreateConstructionGroupContainer /> } />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && isAdministrator(user)} exact path="/applications/:applicationId/groups/:constructionGroupId/update" render={() => <AsyncUpdateConstructionGroupContainer /> } />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && isAdministrator(user)} exact path="/constructions/create" render={() => <AsyncCreateConstructionContainer /> } />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && isAdministrator(user)} exact path="/constructions/:constructionId/update" render={() => <AsyncUpdateConstructionContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/constructions/:applicationId?/:constructionGroupId?" render={() => <AsyncConstructionsContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""}  exact path="/building-blocks/create" render={() => <AsyncCreateBuildingBlockContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""}  exact path="/building-blocks/:constructionId/create" render={() => <AsyncCreateBuildingBlockContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""}  exact path="/building-blocks/:buildingBlockId/update" render={() => <AsyncUpdateBuildingBlockContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""}  exact path="/building-blocks/:buildingBlockId/detail" render={() => <AsyncDetailBuildingBlockContainer /> } />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/building-blocks/:constructionId?/" render={() => <AsyncBuildingBlocksContainer /> } />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && isAdministrator(user)} exact path="/users/" render={() => <AsyncUsersContainer /> } />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && isAdministrator(user)} exact path="/users/create" render={() => <AsyncCreateUserContainer /> } />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && isAdministrator(user)} exact path="/users/:userId/update" render={() => <AsyncUpdateUserContainer /> } />
            <Route component={AsyncNotFoundContainer} />
        </Switch>
    );
}

export default Routes;