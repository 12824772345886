import { Exception, defaultException } from "../interfaces/general/exception";
import { ActionType, createAction } from "typesafe-actions";
import { Dispatch } from "redux";


const updateCurrentExceptionSuccess = createAction("exception/UPDATE_CURRENT_SUCCESS")<Exception>();

export const updateCurrentException = (exception: Exception) => {
    return (dispatch: Dispatch) => {
        dispatch(updateCurrentExceptionSuccess(exception));
      };
  };
  
  export type ExceptionActions = ActionType<typeof updateCurrentExceptionSuccess>;
  
  export const exceptionReducer = (state: Exception = defaultException, action: ExceptionActions) => {
    switch (action.type) {
      case "exception/UPDATE_CURRENT_SUCCESS":
        return action.payload;
      default:
        return state;
    }
  };